import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from './react-auth0-wrapper';
import { Role } from './App';
import GraphiQL, { FetcherOpts, FetcherParams } from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import {
  buildClientSchema,
  parse,
  GraphQLSchema,
  getOperationAST,
} from 'graphql';
import 'graphiql/graphiql.css';
import './GraphiQLWrapper.css';
import logo from './logo.png';
import { createClient, Client } from 'graphql-ws';

const DEFAULT_QUERY = `# shift-option/alt-click on a query below to jump to it in the explorer
# option/alt-click on a field in the explorer to select all subfields
`;

interface GraphiQLWrapperProps {
  roles: Role[];
  authToken: string;
}

const isSubscription = (params: FetcherParams) => {
  const { query, operationName } = params;
  const node = parse(query);

  const operation = getOperationAST(node, operationName);
  return operation && operation.operation === 'subscription';
};

const GraphiQLWrapper: React.FC<GraphiQLWrapperProps> = ({
  roles,
  authToken: token,
}) => {
  const { logout } = useAuth0();
  const graphiql = useRef<GraphiQL | null>(null);
  const subscriptionClient = useRef<Client>();
  const currentUnsubscribe = useRef<() => void>();

  const [role, setRole] = useState(
    roles.find((r) => r.isPreferred) || roles[0]
  );
  const [schema, setSchema] = useState<GraphQLSchema>();
  const [query, setQuery] = useState<string | undefined>(DEFAULT_QUERY);
  const [explorerIsOpen, setExplorerIsOpen] = useState(true);
  const [requestTime, setRequestTime] = useState<number>();

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${token}`,
    tenant_user_profile_id: role.id,
  });

  const executeQuery = async (params: FetcherParams) => {
    const startTime = new Date();
    const response = await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAuthHeaders(),
      },
      body: JSON.stringify(params),
    });
    const responseBody = await response.text();
    const endTime = new Date();
    if (params.operationName !== 'IntrospectionQuery')
      setRequestTime(endTime.getTime() - startTime.getTime());
    try {
      return JSON.parse(responseBody);
    } catch (e) {
      return responseBody;
    }
  };

  const fetcher = async (params: FetcherParams, _opts?: FetcherOpts) => {
    const { query, ...restParams } = params;
    const cleanQuery = params.query.replaceAll(/#.*\n/g, '');
    const newParams: FetcherParams = { query: cleanQuery, ...restParams };

    currentUnsubscribe.current?.();

    if (isSubscription(newParams) && subscriptionClient.current) {
      return {
        subscribe: (observer: any) => {
          observer.next('Waiting for subscription to yield data…');

          const subscription = {
            unsubscribe: subscriptionClient.current!.subscribe(newParams, {
              next: observer.next,
              complete: observer.complete,
              error: (err) => {
                if (err instanceof Error) {
                  observer.error(err);
                } else if (err instanceof CloseEvent) {
                  observer.error(
                    new Error(
                      `Socket closed with event ${err.code}` + err.reason
                        ? `: ${err.reason}`
                        : ''
                    )
                  );
                } else if (Array.isArray(err)) {
                  // GraphQLError[]
                  observer.error(
                    new Error(err.map(({ message }) => message).join(', '))
                  );
                }
              },
            }),
          };
          currentUnsubscribe.current = subscription.unsubscribe;
          return subscription;
        },
      };
    } else {
      const result = await executeQuery(newParams);
      if (
        !schema &&
        newParams.operationName === 'IntrospectionQuery' &&
        result.data
      )
        setSchema(buildClientSchema(result.data));
      return result;
    }
  };

  const handleInspectOperation = (
    cm: any,
    mousePos: { line: Number; ch: Number }
  ) => {
    const parsedQuery = parse(query || '');

    if (!parsedQuery) {
      console.error("Couldn't parse query document");
      return null;
    }

    var token = cm.getTokenAt(mousePos);
    var start = { line: mousePos.line, ch: token.start };
    var end = { line: mousePos.line, ch: token.end };
    var relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end),
    };

    var position = relevantMousePos;

    var def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        console.log('Missing location information for definition');
        return false;
      }

      const { start, end } = definition.loc;
      return start <= position.start && end >= position.end;
    });

    if (!def) {
      console.error(
        'Unable to find definition corresponding to mouse position'
      );
      return null;
    }

    var operationKind =
      def.kind === 'OperationDefinition'
        ? def.operation
        : def.kind === 'FragmentDefinition'
        ? 'fragment'
        : 'unknown';

    var operationName =
      def.kind === 'OperationDefinition' && !!def.name
        ? def.name.value
        : def.kind === 'FragmentDefinition' && !!def.name
        ? def.name.value
        : 'unknown';

    var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

    var el = document.querySelector(selector);
    el && el.scrollIntoView();
  };

  const handleEditQuery = (q?: string): void => {
    setQuery(q);
  };

  const handleToggleExplorer = () => {
    setExplorerIsOpen(!explorerIsOpen);
  };

  useEffect(() => {
    document.title = 'CUT! API Explorer';

    subscriptionClient.current = createClient({
      url: process.env.REACT_APP_SUBSCRIPTION_URL!,
      connectionParams: () => getAuthHeaders(),
    });

    return () => {
      if (subscriptionClient.current) subscriptionClient.current.dispose();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const editor = graphiql.current!.getQueryEditor();
    editor.setOption('extraKeys', {
      ...(editor.options.extraKeys || {}),
      'Shift-Alt-LeftClick': handleInspectOperation,
    });
  });

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={handleEditQuery}
          onRunOperation={(operationName: string | undefined) => {
            graphiql.current!.handleRunQuery(operationName);
          }}
          explorerIsOpen={explorerIsOpen}
          onToggleExplorer={handleToggleExplorer}
        />
        <GraphiQL
          ref={(ref) => (graphiql.current = ref)}
          fetcher={fetcher}
          schema={schema}
          query={query}
          onEditQuery={handleEditQuery}
        >
          <GraphiQL.Logo>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '8px' }}>
                <img src={logo} alt="CUT! Solutions" />
              </div>
              <div>
                Graph<em>i</em>QL
              </div>
            </div>
          </GraphiQL.Logo>
          <GraphiQL.Toolbar>
            <div style={{ marginRight: 'auto' }}>
              <GraphiQL.Button
                onClick={() => graphiql.current!.handlePrettifyQuery()}
                label="Prettify"
                title="Prettify Query (Shift-Ctrl-P)"
              />
              <GraphiQL.Button
                onClick={() => graphiql.current!.handleToggleHistory()}
                label="History"
                title="Show History"
              />
              <GraphiQL.Button
                onClick={handleToggleExplorer}
                label="Explorer"
                title="Toggle Explorer"
              />
            </div>
            <GraphiQL.Menu label={role.description} title="Select User Role">
              {roles
                .sort((a, b) =>
                  (a.tenantName + a.description).localeCompare(
                    b.tenantName + b.description
                  )
                )
                .map((role) => (
                  <GraphiQL.MenuItem
                    label={`${role.description} (${role.tenantName})`}
                    title={role.id}
                    key={role.id}
                    onSelect={() => {
                      currentUnsubscribe.current?.();
                      setRole(role);
                    }}
                  />
                ))}
            </GraphiQL.Menu>
            <GraphiQL.Button
              onClick={() => {
                logout({
                  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                  returnTo: window.location.origin,
                });
              }}
              label="Logout"
              title="Logout User"
            />
          </GraphiQL.Toolbar>
          <GraphiQL.Footer>
            <div
              style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '24px',
              }}
            >{`Request Time - ${requestTime || ''} ms`}</div>
          </GraphiQL.Footer>
        </GraphiQL>
      </div>
    </div>
  );
};

export default GraphiQLWrapper;
