import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from './react-auth0-wrapper';
import * as dotenv from 'dotenv';

dotenv.config();

// A function that routes the user to the right place after login
const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
