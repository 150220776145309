import React, { useEffect, useState } from 'react';
import { useAuth0 } from './react-auth0-wrapper';
import { PulseLoader } from 'react-spinners';
import styles from './App.module.css';
import { GraphQLClient } from 'graphql-request';
import GraphiQLWrapper from './GraphiQLWrapper';

export interface Role {
  id: string;
  description: string;
  tenantName: string;
  isPreferred: boolean;
}

interface QueryPayload {
  availableRoles: Role[];
}

const QUERY = `{
  availableRoles {
    id
    description
    tenantName
    isPreferred
  }
}`;

const App: React.FC = () => {
  const {
    isAuthenticated,
    loading,
    loginWithRedirect,
    getTokenSilently,
    logout,
  } = useAuth0();
  const [token, setToken] = useState<string>();
  const [roles, setRoles] = useState<Role[]>();

  useEffect(() => {
    document.title = 'Logging in...';
  }, []);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!loading && isAuthenticated && !token) {
      getTokenSilently().then((t) => setToken(t));
    } else if (token && !roles) {
      const client = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL!, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      client
        .request<QueryPayload>(QUERY)
        .then((data) => setRoles(data.availableRoles));
    }
  });

  if (!isAuthenticated || loading || !token || !roles) {
    return (
      <div className={styles.container}>
        <PulseLoader color="#f8a52b" size={20} />
      </div>
    );
  }

  if (roles.length < 1) {
    logout({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin,
    });
  }

  return <GraphiQLWrapper authToken={token} roles={roles} />;
};

export default App;
